import React from "react"
import { css, jsx } from "@emotion/core"

import IndustryLayout from "../../components/layouts/industryLayout"

const EcconomicDevelopmentPage = props => (
  <IndustryLayout pageTitle="Economic Development Authority">
    <div>
      <p>
        Evans County, Georgia has the location, sites, infrastructure and
        workforce needed to ensure your company’s success. Explore our site and
        the many opportunities offered by the Claxton-Evans County Economic
        Development Authority.{" "}
        <a href="http://www.claxtonevanseda.com"> www.claxtonevanseda.com</a>
        <br />
        <br />
      </p>
    </div>
  </IndustryLayout>
)

export default EcconomicDevelopmentPage
